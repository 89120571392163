/* eslint-disable */
import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    orders: [],
    isTableBusy: true,
    order: null,
  };
};

const getters = {};

const mutations = {
  SET_ORDERS(state, response) {
    state.orders = response.data.data;
  },
  SET_ISTABLEBUSY(state, val) {
    state.isTableBusy = val;
  },
  SET_ORDER(state, val) {
    state.order = val;
  },
};

const actions = {
  async getOrders({ commit }, payload) {
    console.log("payload", payload);
    const params = QueryBuilder(payload);

    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.get(`/order-management/orders?${params}`)
        .then((response) => {
          if (response.data.error) {
            reject(response);
          } else {
            commit("SET_ORDERS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getOrderById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.get(`/order-management/orders/${id}`)
        .then((response) => {
          commit("SET_ORDER", response.data.data.order);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async updateOrderStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.put(`/order-management/orders/${payload.orderId}/items/${payload.orderItemId}/status`, payload.statusObject)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async exportOrders({ commit }, payload) {
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/order-management/orders/export?${params}`, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
