/* eslint-disable */
import axios from "axios";
import { HTTP } from "@/libs/axios";
import Swal from "sweetalert2";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    SET_BEARER(accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      HTTP.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post("auth/login", payload)
          .then((response) => {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.data.token).replace(/['"]+/g, "")
            );
            commit(
              "SET_BEARER",
              JSON.stringify(response.data.data.token).replace(/['"]+/g, "")
            );
            resolve(response);
          })
          .catch((error) => {
            console.log(error.response);
            Swal.fire({
              title: "დაფიქსირდა შეცდომა!",
              text: "თქვენი მეილი ან პაროლი არასწორია",
              icon: "error",
              confirmButtonText: "Try again",
            });
            reject(error);
          });
      });
    },
    logout(_, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post("auth/logout", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error.response);
            reject(error);
          });
      });
    },
    changePassword(_, payload) {
      return new Promise((resolve, reject) => {
        HTTP.put("profile/password", payload)
          .then((response) => {
            if (response.data.error !== null)
              errorsHandler(response.data.error);
            else resolve(response);
          })
          .catch((error) => {
            console.log(error.response);
            reject(error);
          });
      });
    },
  },
};
