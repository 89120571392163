export default [
  {
    path: "/",
    name: "home",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/privacy-policy/PrivacyPolicy.vue"),
    meta: {
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/account-setting",
    name: "account-setting",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/account-setting/AccountSetting.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Account Settings",
      breadcrumb: [
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin-management",
    name: "admin-management",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/user-management/UserManagement.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Admin Management",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/role-management",
    name: "role-management",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/role-management/RoleManagement.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Role Management",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/customers-management",
  //   name: "customers-management",
  //   // eslint-disable-next-line import/no-extraneous-dependencies
  //   component: () => import("@/views/customers-management/Customers.vue"),
  // },
  {
    path: "/vendor-users",
    name: "vendor-users",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/role-management/VendorUsers.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Vendor Users",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register-product",
    name: "registerProduct",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/RegisterProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Register Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register-sticker",
    name: "registerSticker",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/RegisterSticker.vue"),
  },
  {
    path: "/approved-products",
    name: "approved-products",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/ApprovedProducts.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Approved Products",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/copy-product/:id/:isApproved?",
    name: "copyProduct",
    // eslint-disable-next-line import/no-unresolved
    component: () => import("@/views/products/CopyProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Copy Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-product/:id/:isApproved?",
    name: "editProduct",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/EditProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Edit Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-sticker/:id/:isApproved?",
    name: "editSticker",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/EditSticker.vue"),
  },
  {
    path: "/pending-products",
    name: "pending-products",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/Products.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Pending Products",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register-vendor",
    name: "vendor-register",
    // eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
    component: () => import("@/views/vendor-registration/VendorRegister.vue"),
    meta: {
      emailVerified: false,
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/verification/email/fail",
    name: "failure",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/failurePage.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/verification/email/success",
    name: "success",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/successPage.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/password/set",
    name: "passwordset",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/password-set/PasswordSet.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/order-management/Orders.vue"),
  },
  {
    path: "/orders/:id",
    name: "singleOrders",
    component: () => import("@/views/order-management/SingleOrder.vue"),
  },
  {
    path: "/notifications/",
    name: "notifications",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () =>
      import("@/views/notifications-management/Notifications.vue"),
  },
  {
    path: "/redirect",
    name: "redirectadmin",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/impersonation/Impersonation.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/verification/redirect",
    name: "Redirect",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/Redirect.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];
