import Vue from "vue";
import Vuex from "vuex";

// Modules
import roleStoreModule from "@/views/role-management/roleStoreModule";
import accountStoreModule from "@/views/account-setting/accountStoreModule";
import userStoreModule from "@/views/user-management/userStoreModule";
import vendorRegStoreModule from "@/views/vendor-registration/vendorRegStoreModule";
import productsStoreModule from "@/views/products/productsStoreModule";
import notificationStoreModule from "@/views/notifications-management/notificationStoreModule";
import ordersStoreModule from "@/views/order-management/ordersStoreModule";
import customerStoreModule from "@/views/customers-management/customerStoreModule";
import authStoreModule from "./auth/index";
//
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    vendorRegistration: vendorRegStoreModule,
    products: productsStoreModule,
    accountSettings: accountStoreModule,
    userManagement: userStoreModule,
    roleManagement: roleStoreModule,
    auth: authStoreModule,
    ordersModule: ordersStoreModule,
    customersModule: customerStoreModule,
    notificationModule: notificationStoreModule,
  },
  strict: process.env.DEV,
});
