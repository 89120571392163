/* eslint-disable */
import Swal from 'sweetalert2';

export const errorsHandler = (error) => {

	Swal.fire({
		title: 'Error!',
		text: error.message,
		icon: 'error',
		confirmButtonText: 'Try again',
	});
};

export const successMessage = (title, text) => {
	Swal.fire({
		position: 'center',
		icon: 'success',
		showConfirmButton: false,
		timer: 1500,
		title,
		text,
	});
};
