/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

export const HTTP = axios.create({
	baseURL: process.env.VUE_APP_API,
});

HTTP.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
	'token'
)}`;

HTTP.interceptors.response.use(
	function (response) {
		if (response.data.error) {
			if (response.data.error.code === 2) {
				localStorage.clear();
				window.location.replace('/login');
			}
		}
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.clear();
			window.location.replace('/login');
		}
		return Promise.reject(error);
	}
);

export default axios;
