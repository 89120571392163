/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    branches: [],
    regions: [],
    products: [],
    product: {},
    isConfigurable: false,
    showLoading: false,
    productCategories: [],
    productSubCategories: [],
    productGenericCategories: [],
    productAttributes: [],
  };
};

const getters = {};

const mutations = {
  SET_SHOW_LOADING(state, response) {
    state.showLoading = response;
  },
  SET_PRODUCTS(state, response) {
    state.products = response.data.data;
  },
  SET_PRODUCT(state, response) {
    console.log(response.data.data.product);
    state.product = response.data.data.product;
  },
  SET_PRODUCT_REGIONS(state, response) {
    const payload = [];
    response.data.regions.forEach((e) => {
      e.cities.forEach((c) => {
        c['label'] = c.cityNameGeo + " / " + c.cityNameEng
        payload.push(c);
      });
    });
    state.regions = payload;
  },
  SET_BRANCHES(state, response) {
    const payload = response.data.branches.map((branch) => ({
      id: branch.id,
      label: branch.nameEng,
      name: branch.nameGeo + ' / ' +branch.nameEng,
      isMain: branch.isMain,
    }));
    state.branches = payload;
  },
  SET_PRODUCT_CATEGORIES(state, response) {
    const payloadProductCategories = response.data.productCategories.map(
      (product) => ({
        id: product.productCategoryId,
        label: product.productCategoryNameEng + ' / ' + product.productCategoryNameGeo,
        children: product.subCategories.map((subCategory) => ({
          id: subCategory.productSubCategoryId,
          label:
            subCategory.productSubCategoryNameEng +
            " / " +
            subCategory.productSubCategoryNameGeo,
          serviceType: subCategory.serviceType,
          children: subCategory.genericCategories.map((genericCategory) => ({
            id: genericCategory.productGenericCategoryId,
            productCategoryId: product.productCategoryId,
            productSubCategoryId: subCategory.productSubCategoryId,
            label:
              genericCategory.productGenericCategoryNameEng +
              " / " +
              genericCategory.productGenericCategoryNameGeo,
            emotyCommissionFeePercent:
              genericCategory.emotyCommissionFeePercent,
            giftType: genericCategory.giftType,
            subCategoryId: subCategory.productSubCategoryId,
            serviceType: subCategory.serviceType,
          })),
        })),
      })
    );

    state.productCategories = payloadProductCategories;
  },
  SET_PRODUCT_VENDOR_CATEGORIES(state, response) {
    const payloadProductCategories = response.data.productCategories.map(
      (product) => ({
        id: product.productCategoryId,
        label: product.productCategoryNameEng + ' / ' + product.productCategoryNameGeo,
        children: product.subCategories.map((subCategory) => ({
          id: subCategory.productSubCategoryId,
          label:
            subCategory.productSubCategoryNameEng +
            " / " +
            subCategory.productSubCategoryNameGeo,
          serviceType: subCategory.serviceType,
          children: subCategory.genericCategories.map((genericCategory) => ({
            id: genericCategory.productGenericCategoryId,
            productCategoryId: product.productCategoryId,
            productSubCategoryId: subCategory.productSubCategoryId,
            label:
              genericCategory.productGenericCategoryNameEng +
              " / " +
              genericCategory.productGenericCategoryNameGeo,
            emotyCommissionFeePercent:
              genericCategory.emotyCommissionFeePercent,
            giftType: genericCategory.giftType,
            subCategoryId: subCategory.productSubCategoryId,
            serviceType: subCategory.serviceType,
          })),
        })),
      })
    );

    state.productCategories = payloadProductCategories;
  },
  SET_PRODUCT_ATTRIBUTES(state, response) {
    state.productAttributes = response.data.productAttributes.map((e) => {
      e.value = { id: e.id, valueEng: "", valueGeo: "" };
      return e;
    });
    const checkIsConfigurable = (obj) => obj.isConfigurable === true;
    state.isConfigurable = state.productAttributes.some(checkIsConfigurable);
  },
};

const actions = {
  async createProduct({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.post(`/product-management/review-products`, payload.formData, {
        header: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else successMessage("Successfully", "Product Created");
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async updateSticker({ commit }, payload) {
    const stickerType = payload.approved ? "stickers" : "review-stickers";
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.put(
        `/product-management/${stickerType}/${payload.id}`,
        payload.formData,
        {
          header: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Sticker ");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async createSticker({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.post(`/product-management/review-stickers`, payload, {
        header: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Sticker Created");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async editProduct({ commit }, payload) {
    // TODO quick fix, needs to be improved
    let count = Array.from(payload.formData.keys()).length
    for (const pair of payload.formData.entries()) {
      count -= 1;
      if (count > 0 && pair[0].includes("GalleryImageUpdate[NewImages]")) {
        payload.formData.append("GalleryImageUpdate.NewImages", pair[1])
        delete(pair[0])
      }
    }
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.put(
        `/product-management/${payload.type}/${payload.id}`,
        payload.formData,
        {
          header: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else successMessage("Successfully", "Product Created");
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async hideProduct({ commit }, payload) {
    console.log(payload);
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/product-management/products/${payload.id}/hide`, {
        hideUntilAt: payload.hiddenUntilAt,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async unhideProduct({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/product-management/products/${payload.id}/unhide`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async getProducts({ commit }, payload) {
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/${payload.productType}?` + params)
        .then((response) => {
          commit("SET_PRODUCTS", response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getProductById({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/review-products/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PRODUCT", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async deleteApprovedProductById({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/product-management/products/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async deleteProductById({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/product-management/review-products/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getApprovedProductById({ commit }, id) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/products/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PRODUCT", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => commit("SET_SHOW_LOADING", false));
    });
  },
  async getBranches({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/branches`)
        .then((response) => {
          commit("SET_BRANCHES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getRegions({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/regions`)
        .then((response) => {
          commit("SET_PRODUCT_REGIONS", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getProductCategories({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/product-categories`)
        .then((response) => {
          commit("SET_PRODUCT_CATEGORIES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getProductCategoriesForVendor({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/product-vendor-categories`)
        .then((response) => {
          commit("SET_PRODUCT_VENDOR_CATEGORIES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getProductAttributes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(
        `/product-management/productSubCategories/${payload.id}/productAttributes`
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PRODUCT_ATTRIBUTES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async exportProducts({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.get(
        `/product-management/products/${payload.from}/${payload.to}/export`,
        { responseType: "blob" }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_SHOW_LOADING", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
