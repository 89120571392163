/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    productCategories: [],
    errors: {
      mobile: null,
      email: null,
      idCode: null,
    },
    verificationStatus: null,
    verification: {
      error: null,
    },
    verificationEmail: null,
  };
};

const getters = {};

const mutations = {
  SET_PRODUCT_CATEGORIES(state, response) {
    console.log(response);
    const payload = response.data.productCategories.map((product) => ({
      id: product.productCategoryId,
      label:
        product.productCategoryNameGeo + " / " + product.productCategoryNameEng,
      children: product.subCategories.map((subCategory) => ({
        categoryId: product.productCategoryId,
        subCategoryId: subCategory.productSubCategoryId,
        label:
          subCategory.productSubCategoryNameGeo +
          " / " +
          subCategory.productSubCategoryNameEng,
      })),
    }));
    state.productCategories = payload;
  },
  SET_EMAIL_ERROR(state, response) {
    let data = response;
    state.errors.email = data;
  },
  SET_VERIFICATION(state, response) {
    let data = response;
    state.verificationStatus = data;
  },
  SET_VERIFICATION_EMAIL(state, response) {
    let data = response;
    state.verificationEmail = data;
  },
  SET_VERIFICATION_IDENTIFIER(state, response) {
    let data = response;
    state.verification = data;
  },
  SET_MOBILE_ERROR(state, response) {
    let data = response;
    state.errors.mobile = data;
  },
  SET_ID_ERROR(state, response) {
    let data = response;
    state.errors.idCode = data;
  },
  CLEAR_ERRORS(state) {
    state.errors = {
      mobile: null,
      email: null,
      idCode: null,
    };
  },
  SET_BEARER(accessToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    HTTP.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  },
};

const actions = {
  async vendorRegistration({commit}, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/registration-service/vendors`, {
        identificationCode: payload.identificationCode,
        email: payload.email,
        isIndividualEntrepreneur: payload.isIndividualEntrepreneur,
        mobile: payload.mobile,
        password: payload.password,
      })
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            localStorage.setItem(
                "token",
                JSON.stringify(response.data.data.token).replace(/['"]+/g, "")
            );
            commit(
                "SET_BEARER",
                JSON.stringify(response.data.data.token).replace(/['"]+/g, "")
            );
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getProductCategories({ commit }, payload) {
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/registration-service/product-categories?${params}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PRODUCT_CATEGORIES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  clearErrors({ commit }) {
    commit("CLEAR_ERRORS");
  },
  async mobileVerificationCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/verification/mobile-verification-code`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            commit("SET_VERIFICATION_IDENTIFIER", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async mobileVerification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/verification/verify-mobile`, {
        identifier: payload.identifier,
        code: payload.code,
      })
        .then((response) => {
          commit("SET_VERIFICATION", response.data);
          console.log(response.data);
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async mobileCheck({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/registration-service/mobile-check/${payload}`)
        .then((response) => {
          commit("SET_MOBILE_ERROR", response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async emailVerificationLink({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/verification/email-verification-link`)
        .then((response) => {
          resolve(response);
          if (!response.data.error) {
            commit("SET_VERIFICATION_EMAIL", response.data);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async emailCheck({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/registration-service/email-check/${payload}`)
        .then((response) => {
          commit("SET_EMAIL_ERROR", response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async identificationCodeCheck({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/registration-service/identificationCode-check/${payload}`)
        .then((response) => {
          commit("SET_ID_ERROR", response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async verificationRedirect({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.put(`/verification/email/${payload.hash}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
