/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";
import Swal from "sweetalert2";

const state = () => {
  return {
    users: [],
    userModules: {
      data: {
        modules: [],
      },
      error: {},
    },
    vendorToken: null,
  };
};

const getters = {};

const mutations = {
  SET_USERS(state, response) {
    state.users = response;
  },
  SET_USER_MODULES(state, response) {
    let data = response;
    state.userModules = data;
  },
  SET_VENDOR_TOKEN(state, response) {
    state.vendorToken = response.data.token;
  },
};

const actions = {
  async getUsers({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-user-management/vendor-users`)
        .then((response) => {
          commit("SET_USERS", response.data.data.vendorUsers);
          if (response.data.error) errorsHandler(response.data.error);
          else resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async registerUser(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/vendor-user-management/vendor-users`, {
        email: payload.email,
        mobile: payload.mobile,
        userName: payload.userName,
      })
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            successMessage("Successfully", "User Created");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getUserModules({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/auth/modules`)
        .then((response) => {
          commit("SET_USER_MODULES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async resetUserPassword(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.put(`/vendor-user-management/vendor-users/${payload.id}/password`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else successMessage("Successfully", "User's Password Reseted");
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setUserPassword(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/profile/password`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async deleteUser(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/vendor-user-management/vendor-users/${payload.id}`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            successMessage("Successfully", "User Deleted");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async vendorSessionAuth({ commit }, hash) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/auth/login/${hash}`)
        .then((response) => {
          commit("SET_VENDOR_TOKEN", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
