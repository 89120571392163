/* eslint-disable */
const QueryBuilder = (query) => {
  let finalQuery = "";
  for (const key in query) {
    finalQuery += `${key}=${query[key]}&`;
  }
  return finalQuery.slice(0, -1);
};

export default QueryBuilder;
