/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";

const state = () => {
  return {
    userApplication: null,
    userData: null,
    logo: null,
    showLoading: false,
    contractStatus: null,
    contractData: null,
    brandName: null,
  };
};

const getters = {};

const mutations = {
  SET_USER_INFO(state, response) {
    state.userData = response;
    state.logo = response.data?.info.logoUrl;
    if (response.data) {
      localStorage.setItem(
        "userRoles",
        JSON.stringify(response.data.info.roles)
      );
    } else {
      localStorage.setItem("userRoles", JSON.stringify([]));
    }
  },
  SET_USER_APPLICATION(state, response) {
    state.userApplication = response.data;
    state.brandName = `${response.data?.application?.brandNameGeo} / ${response.data?.application?.brandNameEng}`;
  },
  SET_SHOW_LOADING(state, response) {
    state.showLoading = response;
  },
  SET_CONTRACT_STATUS(state, response) {
    state.contractStatus = response.data.data.status;
  },
  SET_CONTRACT(state, response) {
    state.contractData = response.data.data;
  },
};

const actions = {
  async getUserInfo({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/profile/info`)
        .then((response) => {
          commit("SET_USER_INFO", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async vendorApplication({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      if (payload.create) {
        HTTP.post(`/profile/application`, payload.formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            if (response.data.error) errorsHandler(response.data.error);
            else resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
          .finally(() => commit("SET_SHOW_LOADING", false));
      } else {
        HTTP.put(`/profile/application`, payload.formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            if (response.data.error) errorsHandler(response.data.error);
            else resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
          .finally(() => commit("SET_SHOW_LOADING", false));
      }
    });
  },
  async getUserApplication({ commit }, _) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/profile/info/application`)
        .then((response) => {
          commit("SET_USER_APPLICATION", response.data);
          resolve(response);
          commit("SET_SHOW_LOADING", false);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async updateUserInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.put(`/profile/application`, {
        userName: payload.userName,
        email: payload.email,
        mobile: payload.mobile,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_USER_INFO", response.data.data.info);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getContractStatus({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/profile/contractAttachment/status`)
        .then((response) => {
          console.log("contract status", response);
          commit("SET_CONTRACT_STATUS", response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async getContract({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/profile/contractAttachment`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CONTRACT", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  async uploadContract(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/profile/contractAttachment`, payload, {
        header: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else successMessage("Successfully", "Uploaded Contract");
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
