/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import pages from "./routes/pages";
import store from "@/store";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        redirectIfLoggedIn: false,
      },
    },
    ...pages,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  console.log(_);

  if (!canNavigate(to)) {
    // check path here
    // Redirect to login if not logged in
    if (to.meta.isStatusPage) {
      console.log(to.meta, "sp");
      return next();
    }
    if (!isLoggedIn) return next({ name: "login" });

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }
  const user = store.state.accountSettings.userData;
  if (to.meta.emailVerified && user) {

    if (user.error) {
      if (user.error.code === 9) next("/user-verify");
      else if (user.error.code === 10) next("/user-verify");
      else return next();
    }
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {

    // Redirect if logged in
    const userData = getUserData();
    return next({ name: "home" });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
